import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { initReactI18next, I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import global_es from "./local/es/global.json";
import global_en from "./local/en/global.json";

i18next.use(LanguageDetector).use(initReactI18next).init({
  detection: {
    order: ['navigator', 'htmlTag', 'localStorage', 'sessionStorage', 'cookie'],
  },
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);

